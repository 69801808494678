<script lang="ts">
	import { uniqueId } from 'lodash-es'

	const id = uniqueId()
	export let autoComplete = 'off'
	export let value = ''
	export let isLightOverride: boolean = false
</script>

<input
	{id}
	{...$$restProps}
	autocomplete={autoComplete ?? 'off'}
	aria-autocomplete="list"
	class={`w-full h-12 p-4 leading-6  border rounded-md shadow-sm form-input focus:ring-1 border-grey-md focus:border-grey-dk focus:bg-grey-main focus:ring-gray-800 focus:outline-none focus:ring-transparent sm:text-sm ${
		isLightOverride
			? 'bg-white placeholder-brand-gray-5 text-black'
			: 'placeholder-brand-gray-5 dark:placeholder-brand-gray-2 bg-white dark:bg-brand-gray-5 text-black dark:text-white'
	}`}
	on:abort
	on:blur
	on:change
	on:dblclick
	on:input
	on:paste
	on:emptied
	on:contextmenu
	on:focus
	on:invalid
	on:keydown
	on:keypress
	on:keyup
	on:reset
	bind:value
/>
