<script lang="ts">
	import { page } from '$app/stores'
	import { onMount } from 'svelte'
	import state from '$lib/stores/auth-state'
	// import { configureScope } from '@sentry/core'
	import LogRocket from 'logrocket'
	import { identifyGoogleClickUser, reportError, track } from '$src/utils/track'

	onMount(() => {
		const user = $state?.currentUser
		if (
			import.meta.env.MODE === 'development' ||
			user?.email === 'lindsey@airheart.com' ||
			user?.email === 'lmrenken@gmail.com'
		) {
			// console.log(import.meta.env.MODE)
			return
		}
		identifyGoogleClickUser($page?.url)

		try {
			LogRocket.init('yqwzvj/airheart', {
				shouldDebugLog: false,
				shouldParseXHRBlob: true,
			})

			// if ($page?.url?.searchParams?.has('uid')) {
			//   let uid = $page.url.searchParams.get('uid')
			//   LogRocket.identify(uid, {
			//     verification: $page.url.searchParams.get('verification') ?? 'false',
			//   })
			// }

			LogRocket.getSessionURL(function (sessionURL) {
				track('LogRocket Session', {
					sessionURL: sessionURL,
				})
				// configureScope((scope) => {
				//   scope.setExtra('sessionURL', sessionURL)
				// })
			})
		} catch (err) {
			reportError(err)
		}

		// try {
		//   FullStory.init({ orgId: 'ZMX8D', devMode: import.meta.env.DEV })
		// } catch (e) {
		//   reportError(e)
		// }
	})
</script>
