<script lang="ts">
	import { requestIdleCallback } from '$lib/utils/request-idle-callback'
	import { onMount } from 'svelte'

	let gtm: HTMLScriptElement

	function gtmOnLoad() {
		console.log('Google Tag Manager loaded')
	}

	function gtmOnError() {
		console.error('Google Tag Manager failed to load')
	}

	function gtag(...args: any[]) {
		window['dataLayer'].push(arguments)
	}

	onMount(() => {
		window['dataLayer'] = window['dataLayer'] || []

		requestIdleCallback(() => {
			let scripts = [...document.body.getElementsByTagName('script')]
			let hasLoaded = scripts.some((script) => script.src.includes('googletagmanager.com'))
			if (hasLoaded) return

			window['gtag'] = gtag
			gtag('js', new Date())
			gtag('config', 'G-E5WCXWVM07')

			gtm = document.createElement('script')
			gtm.src = 'https://www.googletagmanager.com/gtag/js?id=G-E5WCXWVM07'
			gtm.type = 'text/javascript'
			gtm.async = true
			gtm.defer = true
			gtm.setAttribute('fetchpriority', 'low')
			document.body.append(gtm)
			gtm.addEventListener('load', gtmOnLoad)
			gtm.addEventListener('error', gtmOnError)
		})
	})

	// onDestroy(() => {
	//   if (gtm == null) return
	//   gtm.removeEventListener('load', gtmOnLoad)
	//   gtm.removeEventListener('error', gtmOnError)
	//   document.body.removeChild(gtm)
	//   gtm = null
	// })
</script>
