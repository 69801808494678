<script lang="ts">
	import { extra } from '$lib/stores/modal-state'
	import SignInForm from '../auth/SignInForm.svelte'
	import Modal from './Modal.svelte'

	let title = 'Access Your Airheart Account'
	// $: intent = $extra['signup'].intent

	// switch (intent) {
	// 	case 'signIn':
	// 		title = 'Access Your Airheart Account'
	// 		break
	// 	case 'signUp':
	// 		title = 'Access Your Airheart Account'
	// 		break
	// 	case 'resetPw':
	// 		title = 'Reset Your PAssword'
	// 		break
	// }
</script>

<Modal open={true} {title}>
	<SignInForm isLightOverride={true} />
</Modal>
