import { browser } from '$app/environment'

export function requestIdleCallback(cb: IdleRequestCallback) {
	if (browser) {
		if (typeof window.requestIdleCallback === 'function') {
			return window.requestIdleCallback(cb)
		} else {
			const start = Date.now()
			return setTimeout(function () {
				cb({
					didTimeout: false,
					timeRemaining: function () {
						return Math.max(0, 50 - (Date.now() - start))
					},
				})
			}, 1)
		}
	} else {
		return setTimeout(cb, 1)
	}
}

export function cancelIdleCallback(id: number) {
	if (browser) {
		if (typeof window.cancelIdleCallback === 'function') {
			return window.cancelIdleCallback(id)
		} else {
			return clearTimeout(id)
		}
	} else {
		return clearTimeout(id)
	}
}
