<script lang="ts">
	import modal from '$lib/stores/modal-state'
	import AddCouponModal from './AddCouponModal.svelte'
	import CustomIconSelectModal from './CustomIconSelectModal.svelte'
	import FeaturedImagesModal from './FeaturedImagesModal.svelte'
	import HighlightMenuModal from './HighlightMenuModal.svelte'
	import SignInModal from './SignInModal.svelte'
</script>

{#if $modal == 'signup'}
	<!-- Handles all sub modal states, or 'intents' -->
	<SignInModal />
{/if}

{#if $modal == 'custom-icon'}
	<!-- Handles all sub modal states, or 'intents' -->
	<CustomIconSelectModal />
{/if}

{#if $modal == 'highlight-menu'}
	<!-- Handles all sub modal states, or 'intents' -->
	<HighlightMenuModal />
{/if}

{#if $modal == 'featured-images-menu'}
	<!-- Handles all sub modal states, or 'intents' -->
	<FeaturedImagesModal />
{/if}

{#if $modal == 'add-coupon'}
	<!-- Handles all sub modal states, or 'intents' -->
	<AddCouponModal />
{/if}

<!-- {#if $modal == 'palette'}
	<CommandPalette />
{/if} -->

<!-- {#if $modal == 'localization-select'}
  <LocalizationSelectModal />
{/if} -->
