<script lang="ts">
	import { signInOneTimePassword, signInWithGoogle } from '$lib/authentication/firebase'
	import modal from '$lib/stores/modal-state'
	import { ExclamationCircle } from '@steeze-ui/heroicons'
	import { Icon } from '@steeze-ui/svelte-icon'
	import { track } from '../../../utils/track'
	import EmailPrompt from './EmailPrompt.svelte'
	import isMobileSafari from '$src/utils/browserType'

	let authErrorMessage: string | null = null

	let error: Error | string | null = null
	export let otpSignedUp = false
	export let loading = false
	export let isBusy = false
	let email: string | null = null
	export let isAuthenticating = false
	export let isLightOverride: boolean = false

	// $: currentBrowserMobileSafari = isMobileSafari()
	async function handleGoogleSignIn(event: MouseEvent) {
		event.preventDefault() // Prevent default button action

		if (isBusy) return // Prevent multiple clicks

		try {
			isBusy = true
			const result = await signInWithGoogle()

			if (result != null) {
				modal.close()
			}

			track('Clicked - Sign In with Google - Modal')
		} catch (error) {
			console.error('Google Sign In Error:', error)
			// Handle error (e.g., show error message to user)
		} finally {
			isBusy = false
		}
	}

	function submitOtp(event: CustomEvent) {
		track('SignIn OTP Presubmit')
		loading = true
		const url = window.location.href
		signInOneTimePassword(email, url)
			.then(() => {
				loading = false
				otpSignedUp = true
				track('SignIn OTP')
			})
			.catch((err) => {
				loading = false
				error = err
				track('SignIn OTP Error', { error: err?.toString() })
			})
	}

	function setValue(event: CustomEvent) {
		email = event.detail.value
	}
</script>

<div class="min-w-min">
	<div class="sm:flex sm:items-start">
		<div
			class={`w-full sm:text-left ${
				isLightOverride
					? 'text-black bg-white'
					: 'dark:text-brand-gray-2 text-black dark:bg-brand-gray-5 bg-white'
			}`}
		>
			<slot />

			{#if authErrorMessage}
				<p class="px-2 py-1 text-red-500 bg-red-100 rounded-lg">{authErrorMessage}</p>
			{/if}

			{#if !error && !otpSignedUp}
				<div class="items-center">
					<button
						aria-label="Sign in with Google"
						class="flex items-center justify-center w-full h-12 p-1 pl-2 border rounded-full space-x-2 border-brand-gray-2 focus:outline-none hover:bg-brand-gray-2 dark:hover:bg-brand-gray-4 dark:hover:bg-opacity-20 hover:shadow-sm disabled:bg-brand-gray-2 dark:disabled:bg-brand-gray-4 disabled:cursor-not-allowed"
						disabled={isBusy}
						on:click={handleGoogleSignIn}
					>
						<img
							height={24}
							width={24}
							src={'/visuals/icons/google-color.svg'}
							alt="Google logo"
							class="h-6"
						/>
						<p class={`${isLightOverride ? 'text-black' : 'dark:text-white text-black'}`}>
							Continue with Google
						</p>
					</button>
					<!-- {#if currentBrowserMobileSafari}
						<p
							class={`px-2 pt-1 text-xs tracking-tight text-pretty ${
								isLightOverride
									? 'text-black bg-white'
									: 'dark:text-brand-gray-2 text-black dark:bg-brand-gray-5 bg-white'
							}`}
						>
							Continue with Google is currently not supported for Safari browsers on mobile devices.
							For the time being, please use a different browser or sign in with your email.
						</p>
					{/if} -->

					<div class="relative my-4">
						<div class="absolute inset-0 flex items-center" aria-hidden="true">
							<div class="w-full border-t border-gray-300" />
						</div>
						<div class="relative flex justify-center">
							<span
								class={`px-2 text-sm ${
									isLightOverride
										? 'text-black bg-white'
										: 'dark:text-brand-gray-2 text-black dark:bg-brand-gray-5 bg-white'
								}`}>OR</span
							>
						</div>
					</div>

					<EmailPrompt
						{isLightOverride}
						buttonLabel="Send Confirmation Link"
						on:submit={submitOtp}
						on:change={setValue}
						value={email}
						loading={loading || isAuthenticating}
					/>
				</div>
			{/if}
			{#if otpSignedUp}
				<div
					class={`px-2 my-8 text-sm ${
						isLightOverride
							? 'text-black bg-white'
							: 'dark:text-brand-gray-2 text-black dark:bg-brand-gray-5 bg-white'
					}`}
				>
					<div class="flex">
						<div class="flex-shrink-0">
							<Icon src={ExclamationCircle} class="w-5 h-5 text-brand-primary" aria-hidden="true" />
						</div>
						<div class="ml-3">
							<p>
								We sent a verification link to <span class="font-medium text-brand-primary"
									>{email}</span
								>
							</p>
							<p>Please click the link to finish signing-in.</p>
							<p class="mt-1">
								Please note: this link is only valid for 24 hours, can only be used once, and for
								the same browser on the same device.
							</p>
						</div>
					</div>
				</div>
			{/if}
			{#if error && !loading}
				<div class="py-2">
					<p class="font-medium tracking-wide text-red-500">An Error Occurred</p>
					<p class="text-black dark:text-white">
						There was a problem logging in. Please try again.
					</p>
				</div>
			{/if}
		</div>
	</div>
</div>
