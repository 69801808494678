<script lang="ts">
	import { validateEmail } from '../../../utils/validate'
	import { createEventDispatcher } from 'svelte'
	import Button from '../buttons/Button.svelte'
	import TextField from '../forms/TextField.svelte'

	export let loading = false
	export let formValid = false
	export let buttonLabel = 'Sign In'
	export let value = ''
	export let isLightOverride: boolean = false

	let errorEmail: string = ''

	const dispatch = createEventDispatcher()

	function handleClick(event: CustomEvent<unknown>) {
		event.preventDefault()

		errorEmail = validateEmail(value)
		if (errorEmail === '') {
			dispatch('submit', { value })
		}
	}
	function onBlur(event: FocusEvent) {
		event.preventDefault()
		dispatch('blur')
	}
	function onPaste(event: ClipboardEvent) {
		event.preventDefault()
		dispatch('paste')
	}
	function handleChange(event: InputEvent) {
		event.preventDefault()
		const value = (event.target as HTMLInputElement)?.value?.trim()
		handleValue(value)
	}

	function handleKeyDown(event: KeyboardEvent) {
		errorEmail = validateEmail((event.target as HTMLInputElement)?.value?.trim())
		if (event.key === 'Enter' && errorEmail === '') {
			dispatch('submit', { value })
		}
	}

	function handleValue(value: string) {
		errorEmail = validateEmail(value)
		formValid = errorEmail === ''
		dispatch('change', { value, formValid })
	}
</script>

<div class="flex flex-col items-stretch w-full mt-2 space-y-2">
	<TextField
		{isLightOverride}
		type="email"
		name="email"
		required
		autoComplete="email"
		placeholder="Enter your email"
		on:blur={onBlur}
		on:paste={onPaste}
		on:input={handleChange}
		on:keydown={handleKeyDown}
		{value}
	/>

	<span class={`text-red-500 text-xs h-[12px] ${errorEmail == '' && 'opacity-0'}`}
		>{errorEmail}</span
	>
	<Button
		aria-label="submit"
		on:click={handleClick}
		disabled={!formValid}
		size="md"
		class="w-full sm:w-auto"
		{loading}
	>
		{buttonLabel}
	</Button>
</div>
