<script lang="ts">
	import { track } from '$src/utils/track'
	import Modal from './Modal.svelte'
	import { page } from '$app/stores'
	import { getContextClient, queryStore } from '@urql/svelte'
	import { BlockType } from '$lib/graphql/enums'
	import {
		BlockDocument,
		type BlockQuery,
		type BlockQueryVariables,
	} from '$lib/queries/generated/QueryBlock'
	import { browser } from '$app/environment'
	import ListItemTemplate from './AddToTrip/ListItemTemplate.svelte'
	import {
		DeleteBlockDocument,
		type DeleteBlockMutation,
		type DeleteBlockMutationVariables,
	} from '$lib/queries/generated/deleteBlock'
	import blockState from '$lib/stores/block-state'
	import { goto } from '$app/navigation'
	import modal from '$lib/stores/modal-state'
	import { fade } from 'svelte/transition'
	import { childBlocksByType } from '$src/utils/defaultValues'

	let title = 'Highlight Block Menu'

	// $: blockId = $page.params?.block
	$: tripId = $page.params?.slug
	$: activeBlockId = new URL($page?.url)?.searchParams?.get('active') ?? null

	const client = getContextClient()

	$: blockStore = queryStore<BlockQuery, BlockQueryVariables>({
		client,
		query: BlockDocument,
		variables: { id: activeBlockId },
		pause:
			!tripId ||
			!browser ||
			!tripId?.startsWith('trp_') ||
			!activeBlockId ||
			!activeBlockId?.startsWith('blk_'),
	})

	$: isIndestructible = $blockStore?.data?.block?.trip?.isPurchased
	const deleteBlock = (vars: DeleteBlockMutationVariables) =>
		client.mutation<DeleteBlockMutation, DeleteBlockMutationVariables>(DeleteBlockDocument, vars)

	function handleDeleteBlock() {
		track('Delete Block', { tripId, activeBlockId })

		deleteBlock({ id: activeBlockId, blockType: BlockType.Highlight, tripId }).then(() => {
			track('Navigate to Trip', { tripId })
			blockState.setRefetchMapBlocks(true)
			goto(`/trips/${tripId}`).then(() => {
				modal.close()
			})
		})
	}
</script>

<Modal open={true} isLightOverride={false} sizeClass="sm:max-w-xl" {title}>
	{@const blockTypesForMenu = childBlocksByType(BlockType.Highlight)}
	<p class="text-xs text-brand-gray-4 my-3">Add To Block</p>
	<!-- {#if blockTypesForMenu?.length > 0}
				<div class="flex flex-col items-start px-4 space-y-2 w-full">
					{#each blockTypesForMenu as item, index (item.title)}
						<div in:fade={{ delay: index * 100, duration: 400 }} class="w-full">
							<ListItemTemplate
								on:click={() => handleSetPanelState(item.clickAction)}
								size="small"
								title={item.title}
								iconUrl={item.iconUrl}
								bgColor={item.bgColor}
								description={item.description}
								isTruncated={false}
							/>
						</div>
					{/each}
				</div>
			{:else} -->
	<p class="space-y-2 w-full text-xs dark:text-brand-gray-2">Nothing to add</p>
	<!-- {/if} -->
	{#if !isIndestructible}
		<p class="text-xs text-brand-gray-4 mb-3 mt-4">Manage Block</p>
		<div class="flex flex-col items-start px-4 space-y-2 w-full">
			<ListItemTemplate
				on:click={handleDeleteBlock}
				size="small"
				title={'Delete Block'}
				iconUrl={'/visuals/icons/delete-white.svg'}
				bgColor={'bg-brand-gray-5'}
				description={'Permanently delete this block and all of its contents'}
				isTruncated={false}
			/>
		</div>
	{/if}
</Modal>
