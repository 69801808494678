import state from '$lib/stores/auth-state'
import type { FirebaseError } from 'firebase/app'
import { reportError } from '../../utils/track'
import { get } from 'svelte/store'
import { cleanURLAndForwardParams, handleOTPSignIn } from './firebase'

// const signedIn = state.signedIn;

export async function otpSignInRedirect(
	setShowEmailConfirmation: Dispatch<boolean>,
	emailValue?: string,
): Promise<void> {
	const url = new URL(window.location.toString())
	console.log('Attempting to sign in with link:', url)

	const hasOtpParams = url.searchParams.has('apiKey') || url.searchParams.has('oobCode')
	if (!hasOtpParams) {
		// don't clean params or do other things
		// if there is no 'apiKey' param
		return
	}
	if (get(state).signedIn) {
		cleanURLAndForwardParams()
		return
	}

	const action = handleOTPSignIn(setShowEmailConfirmation, emailValue)

	if (action) {
		return action
			.then(() => cleanURLAndForwardParams())
			.catch((error: FirebaseError) => {
				reportError(error)
				if (error?.code && error?.message) {
					console.error('Error handleOTPSignIn:' + error.code + ' message:' + error.message)
				} else {
					console.error(error)
				}

				// re-throw the error so that the confirmation flow isn't dismissed
				throw error
			})
	}

	return
}
