<script lang="ts">
	import type { CustomIcon } from '$lib/graphql/types'
	import type { CustomIconDefinitions } from '$lib/utils/custom-icons'
	import { createEventDispatcher } from 'svelte'

	export let categoryIcons: CustomIconDefinitions[] = []
	export let categoryTitle: string = ''

	const dispatch = createEventDispatcher()

	function handleIconSelect(icon: CustomIcon) {
		dispatch('click', { icon })
	}
</script>

<p class="px-2 pt-4 pb-2 text-xs tracking-wide text-brand-gray-3">{categoryTitle}</p>
<div class="px-0 grid grid-cols-8 gap-0">
	{#each categoryIcons as icon}
		<button
			on:click={() => handleIconSelect(icon.name)}
			class="hover:bg-brand-gray-6 p-2 rounded-md mx-auto"
		>
			<img src={`/visuals/icons/custom/white/${icon.svgUrl}`} alt="icon select" class="h-6 w-6" />
		</button>
	{/each}
</div>
